<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__btn">
					<i class="icon icon-location"></i>
				</span>
				<span class="nav__text">
					강남/논현/삼성
				</span>
			</p>
			<a href="#" class="nav__btn">
				<i class="icon icon-map"></i>
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub inn">
			<!-- category -->
			<section class="listsearch">
				<div class="listsearch__wrap">
					<div class="listsearch__inner">
						<div class="listsearch__box">
							<span class="listsearch__icon">
								<i class="icon icon-date"></i>
							</span>
							<input type="text" class="listsearch__ipt" value="11.11(목) ~ 11.12(금)" readonly>
						</div>
					</div>
					<div class="listsearch__inner">
						<div class="listsearch__box">
							<span class="listsearch__icon">
								<i class="icon icon-member"></i>
							</span>
							<input type="text" class="listsearch__ipt" value="성인 2명, 아동 2명" readonly>
						</div>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="menus">
						<div class="menus__wrap">
							<!-- [D] 활성화된 지역에 active 클래스 추가 -->
							<!-- [D] 나만의 숙소찾기 시 노출되는 숙소리스트는 숙소유형 탭이 추가 -->
							<div class="menus__box">
								<div class="menus__text">모텔</div>
								<div class="menus__text active">호텔</div>
								<div class="menus__text">펜션·풀빌라</div>
								<div class="menus__text">리조트</div>
								<div class="menus__text">캠핑장</div>
								<div class="menus__text">게하</div>
							</div>
						</div>
					</div>
				</div>
				<div class="listbox">
					<div class="box">
						<div class="list__meta">
							<div class="list__check">
								<div class="checkbox">
									<label>
										<input type="checkbox" class="chk blind">
										<span class="checkbox__bg"></span>
										쿠폰할인 가능한 숙소
									</label>
								</div>
							</div>
							<div class="list__option">
								<button type="button">
									<i class="icon icon-align">
										정렬
									</i>
								</button>
								<button type="button">
									<i class="icon icon-filter">
										필터
									</i>
								</button>
							</div>
						</div>
					</div>
					<!-- [REST] 숙소 리스트 -->
					<ul class="list list--full">
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
										<span class="badge__text purple">
											선착순 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<div class="list__imagebox noneimage">
												<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp01.jpg') + ')'}">
												</div>
											</div>
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													환상적인 루프탑이 있을까요? 없을까요?
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
											</div>
											<h3 class="list__title info-title">
												전주 한옥에코빌리지 펜션
											</h3>
											<div class="list__distance info-distance">
												역삼역 1번 출구에서 145m
											</div>
											<div class="list__have info-have">
												최대 대실 5시간 객실 보유
											</div>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">대실</span>
													5시간
												</div>
												<div class="list__info-price">
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
											<li>
												<div class="list__info-type">
													<span class="list__badge list__badge--strong">숙박</span>
													18시부터
												</div>
												<div class="list__info-price">
													<div class="info-price__sale">
														<strong>14%</strong>
														<span>35,000</span>
													</div>
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
										<div class="list__hashtag">
											<span>#서울모텔</span>
											<span>#강남모텔</span>
											<span>#셀프체크인</span>
										</div>
										<div class="getevent">
											<div class="getevent__coupon">단골쿠폰</div>
											<div class="getevent__wrap">
												<div class="getevent__title">
													이벤트
												</div>
												<p class="getevent__description ellipsis">
													주중 무한대실 이벤트! 최장 10시간
													주중 무한대실 이벤트! 최장 10시간
												</p>
											</div>
										</div>
									</div>
								</router-link>
								<ul class="item__menu">
									<li>
										<a href="#">
											<i class="icon icon-pick--point">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="item">
								<a class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__imagebox noneimage">
											<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp01.jpg') + ')'}">
											</div>
										</div>
										<div class="list__data">
											<div class="list__slogan info-slogan">
												환상적인 루프탑이 있을까요? 없을까요?
											</div>
											<h3 class="list__title info-title">
												전주 한옥에코빌리지 펜션
											</h3>
											<div class="list__distance info-distance">
												역삼역 1번 출구에서 145m
											</div>
											<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
												<span class="list__gradnum info-gradnum">
													9.7
												</span>
												<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
											</div>
											<div class="list__have info-have">
												최대 대실 5시간 객실 보유
											</div>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">대실</span>
													5시간
												</div>
												<div class="list__info-price">
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
											<li>
												<div class="list__info-type">
													<span class="list__badge list__badge--strong">숙박</span>
													18시부터
												</div>
												<div class="list__info-price">
													<div class="list__info-current">
														<strong>30,000</strong>원
													</div>
												</div>
											</li>
										</ul>
										<div class="list__hashtag">
											<span>#서울모텔</span>
											<span>#강남모텔</span>
											<span>#셀프체크인</span>
										</div>
										<div class="getevent">
											<div class="getevent__wrap">
												<div class="getevent__title">
													이벤트
												</div>
												<p class="getevent__description ellipsis">
													주중 무한대실 이벤트! 최장 10시간
													주중 무한대실 이벤트! 최장 10시간
												</p>
											</div>
										</div>
									</div>
								</a>
								<ul class="item__menu">
									<li>
										<a href="#">
											<i class="icon icon-pick">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheTop/>
</template>